
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media () {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body { 
  /* background: url(../public/background.jpg);   */
  background: #f7f2f2;
}

.custom  p {
  margin-bottom: 1em;
}

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

@keyframes fadeAnimOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0
  }
}

.premium-fade-in {
  animation: fadeAnim 1s ease-in;
}

.premium-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.rtl-grid {
  direction: rtl;
}